<template>
  <center><Loading  :message="message"/></center>
</template>

<script>

import router from '@/router/index.js'
import Loading from '../components/Loading.vue'

export default {
  name: 'Home',
  components: {
    Loading
  },
  data () {
    return {
      message: "Redirecting you to the Client List page ..."
    }
  },
  created(){
    
    window.setTimeout(this.toLandingPage, 2500);
  },
  methods: {
    toLandingPage () {
      router.push('/clients')
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
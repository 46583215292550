import axios from 'axios'

const next_of_kin = {
    namespaced: true,
    state: {
        contact: null
    },
    mutations: {
        createContact(state, data) {
            state.contact = data
        }
    },
    actions: {
        createContact({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("clients/emergency-contact/create", {
                        title: data.emergency_contact.title,
                        first_name: data.emergency_contact.first_name,
                        middle_name: data.emergency_contact.middle_name,
                        last_name: data.emergency_contact.last_name,
                        mobile_number: data.emergency_contact.mobile_number,
                        landline: data.emergency_contact.landline,
                        email: data.emergency_contact.email,
                        language: data.emergency_contact.language,
                        client: data.client_id,
                    })
                    .then((response) => {
                        if (response.status == 201) {
                            commit('createContact', response.data)
                            axios
                                .post("clients/client-emergency-contact/create", {
                                    relationship: data.emergency_contact.relationship,
                                    rank: data.emergency_contact.rank,
                                    client: data.client_id,
                                    contact: response.data.contact_id,
                                })
                                .then((response) => {
                                    resolve(response)
                                })
                                .catch((error) => {
                                    reject(error)
                                });
                        }
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })

        },
    },
    getters: {}
}

export default next_of_kin
import { createWebHistory, createRouter } from "vue-router";
import Home from "../views/Home.vue";
import store from '../store/index.js';


const routerHistory = createWebHistory();


const routes = [{
        path: "/",
        name: "Home",
        component: Home,
        requiresAuth: true
    },
    {
        path: "/login",
        name: "Login",
        component: () =>
            import ("../views/Login/Login.vue"),
    },
    {
        path: "/forgot-password",
        name: "forgotPassword",
        component: () =>
            import ("../views/Login/ForgotPassword.vue")
    },
    {
        path: "/recover-password",
        name: "recoverPassword",
        component: () =>
            import ("../views/Login/RecoverPassword.vue")
    },
    {
        path: "/reset-password/:resetKey",
        name: "resetPassword",
        component: () =>
            import ("../views/Login/ResetPassword.vue")
    },
    {
        path: "/clients",
        component: () =>
            import ("../views/Client/ClientRoot.vue"),
        meta: {
            requiresAuth: true
        },
        children: [{
                path: "",
                component: () =>
                    import ("../views/Client/NewClientList.vue"),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "add",
                name: "add-client",
                component: () =>
                    import ("../views/Client/ClientAdd.vue"),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: ":id/edit",
                name: 'edit',
                component: () =>
                    import ("../views/Client/ClientEdit.vue"),
                meta: {
                    requiresAuth: true
                },
            },
            {
                path: ":id/add-case-record",
                name: 'add-case-record',
                component: () =>
                    import ("../views/Client/Forms/ClientCaseRecordsAdd.vue"),
                meta: {
                    requiresAuth: true
                },
            },
            {
                path: ":id/edit-case-record/:case_id",
                name: 'edit-case-record',
                component: () =>
                    import ("../views/Client/Forms/ClientCaseRecordsEdit.vue"),
                meta: {
                    requiresAuth: true
                },
            },
        ]
    },
    {
        path: "/practitioners",
        component: () =>
            import ("../views/Practitioner/PractitionerRoot.vue"),
        meta: {
            requiresAuth: true
        },
        children: [{
                path: "",
                name: "practitioner-list",
                component: () =>
                    import ("../views/Practitioner/NewPractitionerList.vue"),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "add",
                name: "practitioner-add",
                component: () =>
                import ("../views/Practitioner/PractitionerAdd.vue"),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: ":id/edit",
                name: "practitioner-edit",
                component: () =>
                import ("../views/Practitioner/PractitionerEdit.vue"),
                meta: {
                    requiresAuth: true
                }
            },
        ]
    },
    {
        path: "/formulary",
        component: () =>
            import ("../views/Formulary/FormularyList.vue"),
        meta: {
            requiresAuth: true
        },
    },
    {
        path: "/formulary/:id/edit",
        name: "formulary-edit",
        component: () =>
        import ("../views/Formulary/FormularyEdit.vue"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/consumables",
        component: () =>
            import ("../views/Formulary/Consumables.vue"),
        meta: {
            requiresAuth: true
        },
    },
    {
        path: "/consumable/:id/edit",
        name: "consumable-edit",
        component: () =>
        import ("../views/Formulary/ConsumablesEdit.vue"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/resource-providers",
        component: () =>
            import ("../views/Formulary/ResourceProvidersList.vue"),
        meta: {
            requiresAuth: true
        },
    },
    {
        path: "/resource-providers/add",
        name: "resource-providers-add",
        component: () =>
            import ("../views/Formulary/ResourceProvidersAdd.vue"),
        meta: {
            requiresAuth: true
        },
    },
    {
        path: "/:id/edit",
        name: "resource-providers-edit",
        component: () =>
        import ("../views/Formulary/ResourceProvidersEdit.vue"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/clinical-alliances",
        component: () =>
            import ("../views/ClinicalAlliance/ClinicalAllianceRoot.vue"),
        meta: {
            requiresAuth: true
        },
        children: [{
                path: "",
                component: () =>
                    import ("../views/ClinicalAlliance/ClinicalAllianceList.vue"),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "add",
                name: "add-clinical-alliance",
                component: () =>
                    import ("../views/ClinicalAlliance/ClinicalAllianceAdd.vue"),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: ":id/edit",
                name: 'clinical-alliance-edit',
                component: () =>
                    import ("../views/ClinicalAlliance/ClinicalAllianceEdit.vue"),
                meta: {
                    requiresAuth: true
                },
            },
            {
                path: ":id/add-case-record",
                name: 'add-case-record',
                component: () =>
                    import ("../views/Client/Forms/ClientCaseRecordsAdd.vue"),
                meta: {
                    requiresAuth: true
                },
            },
            {
                path: ":id/edit-case-record/:case_id",
                name: 'edit-case-record',
                component: () =>
                    import ("../views/Client/Forms/ClientCaseRecordsEdit.vue"),
                meta: {
                    requiresAuth: true
                },
            },
        ]
    },
    {
        path: "/facilities",
        component: () =>
            import ("../views/Facility/FacilityRoot.vue"),
        meta: {
            requiresAuth: true
        },
        children: [{
                path: "",
                component: () =>
                    import ("../views/Facility/FacilityList.vue"),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "add",
                name: "add-facility",
                component: () =>
                    import ("../views/Facility/FacilityAdd.vue"),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: ":id/edit",
                name: 'facility-edit',
                component: () =>
                    import ("../views/Facility/FacilityEdit.vue"),
                meta: {
                    requiresAuth: true
                },
            },
        ]
    },
    {
        path: "/funding-options",
        component: () =>
            import ("../views/Admin/FundingOptionRoot.vue"),
        meta: {
            requiresAuth: true
        },
        children: [{
                path: "",
                component: () =>
                    import ("../views/Admin/FundingOptionList.vue"),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "add",
                name: "funding-option-add",
                component: () =>
                    import ("../views/Admin/FundingOptionAdd.vue"),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: ":id/edit",
                name: 'funding-option-edit',
                component: () =>
                    import ("../views/Admin/FundingOptionEdit.vue"),
                meta: {
                    requiresAuth: true
                },
            },
        ]
    },
];

const router = createRouter({
    history: routerHistory,
    routes: routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters['login/isLoggedIn']) {
            next()
            return
        }
        next('/login')
    } else {
        next()
    }
})

export default router;
import { createApp } from "vue";
import App from "./App.vue";
import axios from "axios";
import router from "./router";
import store from "./store/index.js";
import Toaster from "@meforma/vue-toaster";
import "./assets/css/main.css";
import "@/assets/css/tailwind.css";
<link rel="stylesheet" href="https://rsms.me/inter/inter.css"></link>;

//axios defaults
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

if (document.location.toString().indexOf("cms.staging.alignd.co.za") > -1) {
  axios.defaults.baseURL = "https://api.staging.alignd.co.za";
}

axios.interceptors.request.use(
  async (request) => {
    if (store.getters["login/isLoggedIn"] && !request.url.includes("login")) {
      request.headers.Authorization = "Token " + localStorage.getItem("token");
    }
    return request;
  },
  function (err) {
    return new Promise(function (resolve, reject) {
      if (err.status === 401 && err.config) {
        store.dispatch("login/logoutUser");
        store.dispatch("login/authError");
        resolve();
      }
      reject(err);
      throw err;
    });
  }
);

createApp(App).use(router).use(Toaster).mount("#app");

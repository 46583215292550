<template>
    <navigation v-if="isLoggedIn" />

    <router-view></router-view>
</template>

<script>
import store from "@/store/index.js";
import router from "@/router/index.js";
import Navigation from "./components/Navigation.vue";

export default {
    components: {
        Navigation,
    },
    data() {
        return {};
    },
    computed: {
        isLoggedIn() {
            const routename = router.currentRoute.value.path;
            if (store.getters["login/isLoggedIn"] && routename == "/") {
                return false;
            }
            return store.getters["login/isLoggedIn"];
        },
    },
    methods: {
        logout() {
            store.dispatch("login/logoutUser").then(() => {
                router.push("/login");
            });
        },
    },
};
</script>

<style>
    @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

    #app {
        font-family: "Poppins", Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
    }
</style>

<template>
<div>
    <h3 class="mt-2 text-sm font-medium text-gray-900">{{ message }}</h3>
</div>
</template>

<script>
export default {
    props: {
        message: String
    },
}
</script>

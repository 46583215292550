const current_facility = {
    state: {
        facility_details: {
            facility_id: null,
            name: null,
            email: null,
            mobile_number: null,
            landline_number: null,
            province: null,
            unit_number: null,
            building_name: null,
            street_number: null,
            street_name: null,
            suburb: null,
            city: null,
            postal_code: null,
            location: null,
            latitude: null,
            longitude: null,
            registration_number: null,
            practice_number: null,
            facility_type: null,
            signed_mou: false,
            mou_date_signed: null,
        }
    },
    getters: {
        getFacilityDetails: state => state.facility_details
    }
}

export default current_facility;
import axios from 'axios'

// Firebase
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCwzWA3Zt0diL1D7jVd2_mYCGtyP7esQeM",
  authDomain: "smart-processor-303316.firebaseapp.com",
  projectId: "smart-processor-303316",
  storageBucket: "smart-processor-303316.appspot.com",
  messagingSenderId: "155396496738",
  appId: "1:155396496738:web:b69eebd0b7b34b2c4e203b",
};

const app = initializeApp(firebaseConfig);

const login = {
  namespaced: true,
  state: {
    status: '',
    loggedIn: false,
    token: localStorage.getItem('token') || '',
    user: {},
  },
  mutations: {
    loginUser(state, data) {
      state.status = 'success';
      state.token = data.token;
      state.user = { user_email: data.email, authy_id: data.authy_id, user_groups: data.groups };
      axios.defaults.headers.common['Authorization'] = "Token " + data.token;
      localStorage.setItem('token', state.token)
      localStorage.setItem('user', state.user.user_email)
      localStorage.setItem('groups', state.user.user_groups)
    },
    loginError(state) {
      state.status = 'error';
    },
    socialLoginError(state) {
      state.status = 'social_login_error';
    },
    loginRequest(state) {
      state.status = 'loading';
    },
    logoutUser(state) {
      state.status = '';
      state.loggedIn = false;
      state.authy_approved = false;
      state.token = '';
      state.user = {};
    },
  },
  actions: {
    loginUser({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('loginRequest')
        axios.post('login-user/', data)
          .then((response) => {
            commit('loginUser', response.data);
            resolve(response)
          }).catch(err => {
            commit('loginError')
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            localStorage.removeItem('groups')
            reject(err)
          });

      })
    },
    loginWithGoogle({ commit }) {
      return new Promise((resolve, reject) => {
        const auth = getAuth(app);
        const provider = new GoogleAuthProvider();
  
        signInWithPopup(auth, provider)
          .then((result) => {
            const user = result.user;
  
            axios.post('social-login/', {
              email: user.email,
              firebase_token: user.accessToken
            })
            .then((response) => {
              commit('loginUser', response.data);
              resolve(response)
            }).catch(err => {
              commit('socialLoginError')
              localStorage.removeItem('token')
              localStorage.removeItem('user')
              localStorage.removeItem('groups')
              reject(err)
            });
          })
          .catch((error) => {
            commit('socialLoginError');
          });
      })
    },
    logoutUser({ commit }) {
      localStorage.clear();
      commit('logoutUser');
    },
    authError({ commit }) {
      commit('loginError');
    },
    socialAuthError({ commit }) {
      commit('socialLoginError');
    },
  },
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    getToken: state => state.token,
    getUser: state => state.user.user_email,
    getGroups: state => state.user.user_groups
  },
}

export default login
const current_resource_provider = {
    state: {
        general_details: {
            resource_provider_id: null,
            name: null,
            email: null,
            mobile_number: null,
            landline_number: null,
            province: null,
            unit_number: null,
            building_name: null,
            street_number: null,
            street_name: null,
            suburb: null,
            city: null,
            postal_code: null,
            location: null,
            latitude: null,
            longitude: null,
            notes: null,
            practice_number: null,
            is_active: false,
            provider_type: null,
            signed_mou: false,
            mou_date_signed: null,
        }
    },
    mutations: {
        setGeneralDetails() {
        }
    },
    getters: {
        getGeneralDetails: state => state.general_details
    }
}

export default current_resource_provider;
// import { createStore } from 'vuex'
import Vuex from 'vuex'
import case_records from './case_record'
import login from './login'
import next_of_kin from './next_of_kin'
import read_only_endpoints from './read_only'
import current_practitioner from './current_practitioner'
import current_facility from './current_facility'
import current_resource_provider from './current_resource_provider'

// no-unused-vars
const store = new Vuex.Store({
    modules: {
        login: login,
        read_only_endpoints: read_only_endpoints,
        next_of_kin: next_of_kin,
        case_records: case_records,
        current_practitioner: current_practitioner,
        current_facility: current_facility,
        current_resource_provider: current_resource_provider,
    }
})

export default store
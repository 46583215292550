import axios from 'axios'

const read_only_endpoints = {
    namespaced: true,
    state: {
        titles: [],
        languages: [],
        relationships: [],
        termination_reasons: [],
    },
    mutations: {
        getTitles(state, data) {
            state.titles = data
        },
        getLanguages(state, data) {
            state.languages = data
        },
        getRelationships(state, data) {
            state.relationships = data
        },
        getTerminationReasons(state, data) {
            state.termination_reasons = data
        }
    },
    actions: {
        getTitles({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get("clients/titles/").then((response) => {
                    commit('getTitles', response.data);
                    resolve(response)
                }).catch(err => {
                    reject(err)
                });
            })

        },
        getLanguages({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get("clients/languages/").then((response) => {
                    commit('getLanguages', response.data);
                    resolve(response)
                }).catch(err => {
                    reject(err)
                });
            });

        },
        getRelationships({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get("clients/relationships/").then((response) => {
                    commit('getRelationships', response.data);
                    resolve(response)
                }).catch(err => {
                    reject(err)
                });
            });

        },
        getTerminationReasons({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get('/alignd_palliative/termination-reason/').then((response) => {
                    commit('getTerminationReasons', response.data);
                    resolve(response)
                }).catch(err => {
                    reject(err)
                });
            });


        }
    },
    getters: {
        getTitles: state => state.titles,
        getLanguages: state => state.languages,
        getRelationships: state => state.relationships,
        getTerminationReasons: state => state.termination_reasons,
    }
}

export default read_only_endpoints
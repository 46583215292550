import axios from 'axios'

const case_records = {
    namespaced: true,
    state: {
        case_records: [],
        case_notes: [],
    },
    mutations: {
        getCaseRecords(state, data) {
            state.case_records = data
        },
        getCaseNotes(state, data) {
            state.case_notes = data
        }
    },
    actions: {
        getCaseRecords({ commit }, data) {
            
            return new Promise((resolve, reject) => {
                if (data.client_id) {
                    axios.get("alignd_palliative/case-record/", { params: { client_id: data.client_id } }).then((response) => {
                        commit('getCaseRecords', response.data);
                        resolve(response)
                    }).catch(err => {
                        reject(err)
                    });
                }
            })
        },
        getCaseNotes({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios.get("alignd_palliative/case-record/notes", {
                    params: {
                        case_id: data.case_id
                    }
                }).then((response) => {
                    commit('getCaseNotes', response.data);
                    resolve(response)
                }).catch(err => {
                    reject(err)
                });
            })

        },
    },
    getters: {
        getCaseRecords: state => state.case_records,
        getCaseNotes: state => state.case_notes,
    }
}

export default case_records
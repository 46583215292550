const current_practitioner = {
    state: {
        personal_details: {
            practitioner_id: null,
            title: null,
            first_name: null,
            middle_name: null,
            last_name: null,
            email: null,
            mobile_number: null,
            landline_number: null,
            language: null,
            province: null,
            unit_number: null,
            building_name: null,
            street_number: null,
            street_name: null,
            suburb: null,
            city: null,
            postal_code: null,
            location: null,
            latitude: null,
            longitude: null,
            profession_membership_number: null,
            practice_number: null,
            is_active: false,
            valid_registration: null,
            date_last_verified: null,
            practice_type: null,
            facility: [],
        }
    },
    mutations: {
        setPersonalDetails() {
        }
    },
    getters: {
        getPersonalDetails: state => state.personal_details
    }
}

export default current_practitioner;
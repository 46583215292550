<template>
<nav class="bg-white border-b border-gray-200">
    <div class="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex justify-between h-16">
            <div class="flex">
                <div class="flex-shrink-0 flex items-center">
                    <router-link to="/"><img class="block lg:hidden h-8 w-auto" src="https://alignd.co.za/wp-content/uploads/2019/04/logo.png" alt="Alignd Logo" /></router-link>
                    <router-link to="/"><img class="hidden lg:block h-8 w-auto" src="https://alignd.co.za/wp-content/uploads/2019/04/logo.png" alt="Alignd Logo" /></router-link>
                </div>

                <div :class="currentPage" class="
                            hidden
                            sm:-my-px sm:ml-6 sm:flex sm:space-x-8
                            text-left
                        ">
                    <!-- Current: "border-alignd-teal text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" -->

                    <div class="dropdown">
                        <div class="
                                    clients
                                    dropbtn
                                    border-transparent
                                    text-gray-500
                                    hover:border-gray-300
                                    hover:text-gray-700
                                    inline-flex
                                    items-center
                                    px-1
                                    pt-1
                                    border-b-2
                                    text-sm
                                    font-medium
                                ">
                            Clients
                        </div>
                        <div class="dropdown-content bg-gray-50">
                            <router-link to="/clients" @click="makeCurrentPage('clients')" class="
                                        border-transparent
                                        text-gray-500
                                        hover:border-gray-300
                                        hover:text-gray-700
                                        px-1
                                        pt-1
                                        text-sm
                                        font-medium
                                    ">List</router-link>
                            <router-link to="/clients/add" @click="makeCurrentPage('clients')" class="
                                        border-transparent
                                        text-gray-500
                                        hover:border-gray-300
                                        hover:text-gray-700
                                        px-1
                                        pt-1
                                        text-sm
                                        font-medium
                                    ">Add</router-link>
                        </div>
                    </div>

                    <div class="dropdown">
                        <div class="
                                    practitioners
                                    dropbtn
                                    border-transparent
                                    text-gray-500
                                    hover:border-gray-300 hover:text-gray-700
                                    inline-flex
                                    items-center
                                    px-1
                                    pt-1
                                    border-b-2
                                    text-sm
                                    font-medium
                                ">
                            Practitioners
                        </div>
                        <div class="dropdown-content bg-gray-50">
                            <router-link to="/practitioners" @click="makeCurrentPage('practitioners')" class="
                                        border-transparent
                                        text-gray-500
                                        hover:border-gray-300
                                        hover:text-gray-700
                                        px-1
                                        pt-1
                                        text-sm
                                        font-medium
                                    ">List</router-link>
                            <router-link to="/practitioners/add" @click="makeCurrentPage('practitioners')" class="
                                        border-transparent
                                        text-gray-500
                                        hover:border-gray-300
                                        hover:text-gray-700
                                        px-1
                                        pt-1
                                        text-sm
                                        font-medium
                                    ">Add</router-link>
                        </div>
                    </div>

                    <div class="dropdown">
                        <div class="
                                    clinical-alliances
                                    dropbtn
                                    border-transparent
                                    text-gray-500
                                    hover:border-gray-300 hover:text-gray-700
                                    inline-flex
                                    items-center
                                    px-1
                                    pt-1
                                    border-b-2
                                    text-sm
                                    font-medium
                                ">
                            Clinical Alliances
                        </div>
                        <div class="dropdown-content bg-gray-50">
                            <router-link to="/clinical-alliances" @click="makeCurrentPage('clinical-alliances')" class="
                                        border-transparent
                                        text-gray-500
                                        hover:border-gray-300
                                        hover:text-gray-700
                                        px-1
                                        pt-1
                                        text-sm
                                        font-medium
                                    ">List</router-link>
                            <router-link to="/clinical-alliances/add" @click="makeCurrentPage('clinical-alliances')" class="
                                        border-transparent
                                        text-gray-500
                                        hover:border-gray-300
                                        hover:text-gray-700
                                        px-1
                                        pt-1
                                        text-sm
                                        font-medium
                                    ">Add</router-link>
                        </div>
                    </div>

                    <div class="dropdown">
                        <div class="
                                    facilities
                                    dropbtn
                                    border-transparent
                                    text-gray-500
                                    hover:border-gray-300 hover:text-gray-700
                                    inline-flex
                                    items-center
                                    px-1
                                    pt-1
                                    border-b-2
                                    text-sm
                                    font-medium
                                ">
                            Facilities
                        </div>
                        <div class="dropdown-content bg-gray-50">
                            <router-link to="/facilities" @click="makeCurrentPage('facilities')" class="
                                        border-transparent
                                        text-gray-500
                                        hover:border-gray-300
                                        hover:text-gray-700
                                        px-1
                                        pt-1
                                        text-sm
                                        font-medium
                                    ">
                                List
                            </router-link>
                            <router-link to="/facilities/add" @click="makeCurrentPage('facilities')" class="
                                        border-transparent
                                        text-gray-500
                                        hover:border-gray-300
                                        hover:text-gray-700
                                        px-1
                                        pt-1
                                        text-sm
                                        font-medium
                                    ">Add</router-link>
                        </div>
                    </div>

                    <div class="dropdown">
                        <div class="
                                    clinical-lookup
                                    dropbtn
                                    border-transparent
                                    text-gray-500
                                    hover:border-gray-300 hover:text-gray-700
                                    inline-flex
                                    items-center
                                    px-1
                                    pt-1
                                    border-b-2
                                    text-sm
                                    font-medium
                                ">
                            Clinical Lookup
                        </div>
                        <div class="dropdown-content bg-gray-50">
                            <router-link to="/formulary" @click="makeCurrentPage('clinical-lookup')" class="
                                        border-transparent
                                        text-gray-500
                                        hover:border-gray-300
                                        hover:text-gray-700
                                        px-1
                                        pt-1
                                        text-sm
                                        font-medium
                                    ">Formulary</router-link>
                            <router-link to="/consumables" @click="makeCurrentPage('clinical-lookup')" class="
                                        border-transparent
                                        text-gray-500
                                        hover:border-gray-300
                                        hover:text-gray-700
                                        px-1
                                        pt-1
                                        text-sm
                                        font-medium
                                    ">Consumables</router-link>
                            <router-link to="/resource-providers" @click="makeCurrentPage('clinical-lookup')" class="
                                        border-transparent
                                        text-gray-500
                                        hover:border-gray-300
                                        hover:text-gray-700
                                        px-1
                                        pt-1
                                        text-sm
                                        font-medium
                                    ">Resource Providers</router-link>
                        </div>
                    </div>

                    <div class="dropdown">
                        <div class="admin-area dropbtn border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium">
                            Admin
                        </div>
                        <div class="dropdown-content bg-gray-50">
                            <router-link to="/funding-options" @click="makeCurrentPage('admin-area')" class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 px-1 pt-1 text-sm font-medium">
                                Scheme Options
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="hidden sm:ml-6 sm:flex sm:items-center">
                <a class="
                            block
                            px-4
                            py-2
                            text-sm text-gray-700
                            cursor-pointer
                        " role="menuitem" tabindex="-1" id="user-menu-item-2" v-if="isLoggedIn" @click="logout">
                    Sign out ( {{ user }} )
                </a>
            </div>
            <div class="-mr-2 flex items-center sm:hidden">
                <!-- Mobile menu button -->
                <button type="button" @click="toggleMobileMenu" class="
                            bg-white
                            inline-flex
                            items-center
                            justify-center
                            p-2
                            rounded-md
                            text-gray-400
                            hover:text-gray-500 hover:bg-gray-100
                            focus:outline-none
                            focus:ring-2
                            focus:ring-offset-2
                            focus:ring-alignd-teal
                        " aria-controls="mobile-menu" aria-expanded="false">
                    <span class="sr-only">Open main menu</span>
                    <!--
              Heroicon name: outline/menu

              Menu open: "hidden", Menu closed: "block"
            -->
                    <svg class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    <!--
              Heroicon name: outline/x

              Menu open: "block", Menu closed: "hidden"
            -->
                    <svg class="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
        </div>
    </div>

    <!-- Mobile menu, show/hide based on menu state. -->
    <div class="sm:hidden" id="mobile-menu" v-if="mobileMenuShown">
        <div class="pt-2 pb-3 space-y-1">
            <!-- Current: "bg-indigo-50 border-alignd-teal text-indigo-700", Default: "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800" -->
            <router-link to="/clients" @click="hideMobileMenu" class="
                        bg-indigo-50
                        border-alignd-brown
                        text-alignd-brown
                        block
                        pl-3
                        pr-4
                        py-2
                        border-l-4
                        text-base
                        font-medium
                    " aria-current="page">
                Clients
            </router-link>

            <router-link to="/practitioners" @click="hideMobileMenu" class="
                        border-transparent
                        text-gray-600
                        hover:bg-gray-50
                        hover:border-gray-300
                        hover:text-gray-800
                        block
                        pl-3
                        pr-4
                        py-2
                        border-l-4
                        text-base
                        font-medium
                    ">
                Practitioners
            </router-link>

            <router-link to="/clinical-alliances" @click="hideMobileMenu" class="
                        border-transparent
                        text-gray-600
                        hover:bg-gray-50
                        hover:border-gray-300
                        hover:text-gray-800
                        block
                        pl-3
                        pr-4
                        py-2
                        border-l-4
                        text-base
                        font-medium
                    ">
                Clinical Alliances
            </router-link>

            <router-link to="/facilities" @click="hideMobileMenu" class="
                        border-transparent
                        text-gray-600
                        hover:bg-gray-50
                        hover:border-gray-300
                        hover:text-gray-800
                        block
                        pl-3
                        pr-4
                        py-2
                        border-l-4
                        text-base
                        font-medium
                    ">
                Facilities
            </router-link>

            <router-link to="/formulary" @click="hideMobileMenu" class="
                        border-transparent
                        text-gray-600
                        hover:bg-gray-50
                        hover:border-gray-300
                        hover:text-gray-800
                        block
                        pl-3
                        pr-4
                        py-2
                        border-l-4
                        text-base
                        font-medium
                    ">
                Formulary
            </router-link>

            <router-link to="/consumables" @click="hideMobileMenu" class="
                        border-transparent
                        text-gray-600
                        hover:bg-gray-50
                        hover:border-gray-300
                        hover:text-gray-800
                        block
                        pl-3
                        pr-4
                        py-2
                        border-l-4
                        text-base
                        font-medium
                    ">
                Consumables
            </router-link>

            <router-link to="/resource-providers" @click="hideMobileMenu" class="
                        border-transparent
                        text-gray-600
                        hover:bg-gray-50
                        hover:border-gray-300
                        hover:text-gray-800
                        block
                        pl-3
                        pr-4
                        py-2
                        border-l-4
                        text-base
                        font-medium
                    ">
                Resource Providers
            </router-link>
        </div>
        <div class="py-3 border-t border-gray-200">
            <div class="space-y-1">
                <a class="
                            block
                            cursor-pointer
                            px-4
                            py-2
                            text-base
                            font-medium
                            text-gray-500
                            hover:text-gray-800 hover:bg-gray-100
                        " v-if="isLoggedIn" @click="logout">
                    Sign out ( {{ user }} )
                </a>
            </div>
        </div>
    </div>
</nav>
</template>

<script>
import store from "@/store/index.js";
import router from "@/router/index.js";

export default {
    data() {
        return {
            currentPage: "clients",
            user: "",
            mobileMenuShown: false,
        };
    },
    mounted() {
        this.user = localStorage.getItem("user");

        if (window.location.pathname.match("practitioners")) { this.currentPage = "practitioners"; }
        else if (window.location.pathname.match("clinical-alliances")) { this.currentPage = "clinical-alliances"; }
        else if (window.location.pathname.match("facilities")) { this.currentPage = "facilities"; }
        else if (window.location.pathname.match("formulary")) { this.currentPage = "clinical-lookup"; }
        else if (window.location.pathname.match("consumables")) { this.currentPage = "clinical-lookup"; }
        else if (window.location.pathname.match("resource-providers")) { this.currentPage = "clinical-lookup"; }
        else if (window.location.pathname.match("funding-options")) { this.currentPage = "admin-area"; }
    },
    computed: {
        isLoggedIn() {
            return store.getters["login/isLoggedIn"];
        },
    },
    methods: {
        makeCurrentPage(page) {
            this.currentPage = page;
        },
        hideMobileMenu() {
            this.mobileMenuShown = false;
        },
        logout() {
            store.dispatch("login/logoutUser").then(() => {
                router.push("/login");
            });
        },
        toggleMobileMenu() {
            if (!this.mobileMenuShown) {
                this.mobileMenuShown = true;
            } else {
                this.mobileMenuShown = false;
            }
        }
    },
};
</script>

<style>
.navbar {
    -webkit-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
}

.clients .clients,
.practitioners .practitioners,
.clinical-alliances .clinical-alliances,
.facilities .facilities,
.clinical-lookup .clinical-lookup, .admin-area .admin-area {
    border-bottom: 2px solid #225F7A;
    color: rgb(17 24 39);
}
</style>
